const URLLIST = {
  // 登录相关
  good: '/good',
  dict: '/dict',
  login: '/login/ajax',
  checkLogin: '/login/checkLogin',
  loginJump: '/login/credentials', // 单点登录
  logout: '/logout/ajax',
  myPermission: '/myPermission',
  proList: '/projectInfo/findList',
  getCurrentUserInfo: 'getCurrentUserInfo', // 获取用户信息
  // 获取手机验证码
  getVerifyCode: '/login/getVerifyCode',
  // 首页
  // taskList: '/taskInfo/myList',
  taskList: '/taskInfo/khbmyList',
  // checkNotice: '/projectNotice/selectUserNoticePage', // 查询通知
  checkNotice: '/projectNotice/khbselectUserNoticePage', // 查询通知
  toRead: '/projectNotice/toRead', // 标记为已读
  toAllRead: '/projectNotice/toUserAllRead', // 标记全部已读
  userCountList: '/projectTransverseInfo/userCountList', // 项目统计
  // 用户列表
  userList: '/userInfo/allocatedDeptListTemp',
  dictAll: '/configOptionGroup/list',

  // 部门列表
  getDeptList: '/projectTransverseInfo/findDeptList',
  // 下级审批人
  getProjectNextCheck: '/projectOperation/getProjectNextCheck',
  // 院领导
  getAllLeader: '/projectOperation/getAllLeader',

  // 横向项目
  projectList: '/projectTransverseInfo/findList',
  projectDetail: '/projectTransverseInfo/findById',
  projectDel: '/projectTransverseInfo/delete',
  projectMemberList: '/projectMember/list',
  projectMemberAdd: '/projectMember/addProjectMember',
  projectMemberDel: '/projectMember/deleteProjectMember',
  projectMemberEdit: '/projectMember/updateProjectMember',
  // 项目成果
  projectAchievementList: '/projectAchievement/list',
  projectAchievementAdd: '/projectAchievement/addProjectAchievement',
  // 更新项目成果
  projectAchievementEdit: '/projectAchievement/updateProjectAchievement',
  projectAchievementDel: '/projectAchievement/deleteProjectAchievement',
  saveFundFis: '/projectFundFisHorizontal/saveFundFis', // 保存编辑预算经费
  fisList: '/projectFundFisHorizontal/list', // 查询预算经费
  userCompanyList: '/login/userCompanyList', // 导航栏选择公司
  myDetail: '/taskInfo/myDetail', // 获取审核详情接口

  // 上传文件到附件组
  // 获取附件组
  groupFileId: '/attachedGroup/findAttachedGroup',
  attachedFile: '/attachedFile/addFileIntoGroup', // 添加单个附件到附件组
  uploadFile: '/attachedFile/uploadFile',
  downloadUrl: '/ossUpload/downloadProjectFile',
  delFile: '/attachedFile/deleteFile',
  addFileListInGroup: '/attachedFile/addFileListInGroup', // 批量上传附件到附件组（替换附件组中的附件）
  findGroupDeatail: '/attachedGroup/findGroupDeatail', // 通过组id获取附件

  savedDraft: '/projectTransverseInfo/saveProjectDraft', // 保存草稿
  saveApply: '/projectOperation/submitApplyProject', // 立项申请
  saveStart: '/projectOperation/applyProjectStart', // 开工
  saveStop: '/projectOperation/applyProjectStop', // 终止
  saveSuspend: '/projectOperation/applyProjectSuspend', // 暂停
  saveRecover: '/projectOperation/applyProjectResume', // 恢复
  saveComplete: '/projectOperation/applyProjectComplete', // 结项
  rebackProject: '/projectOperation/rebackProject', // 撤销
  approveProject: '/projectOperation/approveProject', // 审核
  singelDetail: '/projectChange/singelDetail', // 获取项目变更草稿状态单一变更详细信息
  memberDetail: '/projectChange/memberDetail', // 成员变更信息接口
  // 变更类型保存草稿接口
  fundsSave: '/projectChange/fundsSave', // 经费变更
  chargeSave: '/projectChange/chargeSave', // 负责人变更
  otherSave: '/projectChange/otherSave', // 其它变更
  timeSave: '/projectChange/timeSave', // 进度变更
  memberSave: '/projectChange/memberSave', // 成员变更
  applyProjectChange: '/projectOperation/applyProjectChange', // 提交变更申请
  oneDetail: '/projectChange/oneDetail', //  根据变更ID获取变更详细信息
  checkDetail: '/projectChange/checkDetail', // 根据项目ID获取变更中详细信息
  addMultiInstance: '/projectOperation/addMultiInstance', // 会签人员加签
  achievementDetail: 'projectChange/achievementDetail', // 成果变更
  achievementSave: 'projectChange/achievementSave', // 成果变更保存草稿
  czhxFundFisDetail: '/projectChange/czhxFundFisDetail', // 财政横向经费变更详情
  czhxFundFisSave: '/projectChange/czhxFundFisSave', // 财政很小经费变更保存草稿
  getProjectScheduleList: '/projectSchedule/getProjectScheduleList', // 获取项目进度计划列表
  addProjectSchedule: '/projectSchedule/addProjectSchedule', // 创新发展项目添加进度计划
  updateProjectSchedule: '/projectSchedule/updateProjectSchedule', // 创新发展项目修改进度计划
  deleteProjectSchedule: '/projectSchedule/deleteProjectSchedule', // 创新发展项目删除进度计划
  saveActualProjectSchedule: '/projectSchedule/saveActualProjectSchedule', // 申请人或负责人修改创新发展项目修改进度计划
  scheduleSave: '/projectChange/scheduleSave', // 创新发展项目进度计划保存草稿
  scheduleDetail: '/projectChange/scheduleDetail', // 创新发展项目获取进度计划草稿详情
  fundZyDetail: '/projectChange/fundZyDetail', // 创新发展项目获取变更经费列表接口
  fundZySave: '/projectChange/fundZySave', // 保存草稿创新发展项目预算经费变更申请表
  zyAddType: '/projectFundZyChange/save', // 编辑保存草稿预算经费信息
  zyDelType: '/projectFundZyChange/delete', //  删除预算经费信息
  // 经费变更相关接口
  addDev: '/projectFundZyChange/addDev', // 新增变更后设备费信息
  getDevDetail: '/projectFundZyChange/getDevDetail', // 获取设备费信息
  getMaterialDetail: '/projectFundZyChange/getMaterialDetail', // 获取材料费信息
  addMaterial: '/projectFundZyChange/addMaterial', // 新增材料费信息
  getBusinessTravelDetail: '/projectFundZyChange/getBusinessTravelDetail', // 获取变更后差旅费信息
  addBusinessTravel: '/projectFundZyChange/addBusinessTravel', // 新增变更后差旅费信息
  addExpert: '/projectFundZyChange/addExpert', // 新增变更后专家费信息
  getExpertDetail: '/projectFundZyChange/getExpertDetail', // 获取专家费信息
  addMeeting: '/projectFundZyChange/addMeeting', // 新增变更后会议费信息
  getMeetingDetail: '/projectFundZyChange/getMeetingDetail', // 获取会议费信息
  addPublish: '/projectFundZyChange/addPublish', // 新增变更后出版文献费信息
  getPublishDetail: '/projectFundZyChange/getPublishDetail', // 获取出版文献费信息
  addProcessing: '/projectFundZyChange/addProcessing', // 新增变更后出版文献费信息
  getProcessingDetail: '/projectFundZyChange/getProcessingDetail', // 获取出版文献费信息
  addSubsidy: '/projectFundZyChange/addSubsidy', // 新增变更后出版文献费信息
  getSubsidyDetail: '/projectFundZyChange/getSubsidyDetail', // 获取出版文献费信息
  addCommunication: '/projectFundZyChange/addCommunication', // 新增变更后出版文献费信息
  getCommunicationDetail: '/projectFundZyChange/getCommunicationDetail', // 获取出版文献费信息
  addManpower: '/projectFundZyChange/addManpower', // 新增变更后出版文献费信息
  getManpowerDetail: '/projectFundZyChange/getManpowerDetail', // 获取出版文献费信息
  addOther: '/projectFundZyChange/addOther', // 新增变更后其他费信息
  getOtherDetail: '/projectFundZyChange/getOtherDetail', // 获取其他费信息

  // 参与单位相关接口
  projectCompanyList: '/projectCompany/list', // 查询项目参与单位
  projectCompanyAdd: '/projectCompany/addProjectCompany', // 新增一个参与单位
  projectCompanyDel: '/projectCompany/deleteProjectCompany', // 删除一个参与单位
  projectCompanyEdit: '/projectCompany/updateProjectCompany', // 修改参与单位信息

  // 中期评审提交
  applyProjectReview: '/projectOperation/applyProjectReview',

  // 创新发展项目经费管理
  projectFundList: '/projectFund/summaryList', // 列表
  projectFundDetail: '/projectFund/detail',
  projectFundAdd: '/projectFund/add', // 添加经费类型
  projectFundSave: '/projectFund/save', // 保存数据
  projectFundDel: '/projectFund/delete', // 删除

  // 财政专项项目经费管理接口
  projectFundCzzxList: '/projectFundCzzx/list', // 查询财政专项预算经费
  projectFundCzzxAdd: '/projectFundCzzx/addProjectFundCzzx', // 新增一个专项预算经费
  projectFundCzzxEdit: '/projectFundCzzx/updateProjectFundCzzx', // 修改一个专项预算经费
  projectFundCzzxSave: '/projectFundCzzx/saveProjectFundCzzxDev', // 保存设施设备费
  projectFundCzzxDevAdd: '/projectFundCzzx/addProjectFundCzzxDev', // 新增一个设施设备费
  projectFundCzzxDevDel: '/projectFundCzzx/deleteProjectFundCzzxDev', // 删除一个设施设备费
  projectFundCzzxDevEdit: '/projectFundCzzx/updateProjectFundCzzxDev', // 修改一个设施设备费
  projectFundCzzxDevList: '/projectFundCzzx/devlist', // 查询设施设备费
  addFirstProjectFundCzzx: '/projectFundCzzx/addFirstProjectFundCzzx', // 添加默认设备费

  // 财政专项项目经费管理变变更
  projectFundCzzxChaSave: '/projectFundCzzxCha/save', // 编辑保存草稿变更预算经费信息
  projectFundCzzxChaDel: '/projectFundCzzxCha/delete', // 删除变更预算经费信息
  projectFundCzzxChaDetail: '/projectFundCzzxCha/getDevDetail', // 获取变更设备费信息
  projectFundCzzxChaAdd: '/projectFundCzzxCha/addDev', // 新增变更后设备费信息
  fundCzzxDetail: '/projectChange/fundCzzxDetail', // 获取财政专项项目预算经费变更详细信息
  fundCzzxSave: '/projectChange/fundCzzxSave', // 保存财政专项项目预算经费变更申请表

  // 财政专项项目只结题不归档
  applyProjectOnlyconclusion: '/projectOperation/applyProjectOnlyconclusion',

  // 附件类型
  // projectTypeList: '/projectType/list',
  projectTypeList: '/projectType/khblist',
  projectTypeAdd: '/projectType/add',
  projectTypeUpdate: '/projectType/update',

  findProcessById: '/projectTransverseInfo/findProcessById', // 暂停、恢复、终止详情接口

  // 附件列表
  findFileList: '/projectTransverseInfo/findFileList',

  // 流程模型相关
  flowModel: '/models',
  flowUp: '/models/uploadFile',
  getProcessInstanceImage: '/process/getProcessInstanceImage', // 获取流程图

  // 评审会
  meetingList: '/reviewMeetingInfo/meetingList',
  meetingDetail: '/reviewMeetingInfo/meetingInfoById',
  meetingClose: '/reviewMeetingInfo/close',
  findListNeedApprove: '/projectInfo/findListNeedApprove',

  // 用户/角色管理
  userListAll: '/userInfo/userList/all', // 用户管理列表
  modifyUserSimpleInfo: '/userInfo/modifyUserSimpleInfo', // 用户列表编辑
  roleList: '/privilegeRole/list', // 角色管理
  createRole: '/privilegeRole/createRole', // 创建角色
  privilegeRole: '/privilegeRole/delete', // 删除角色
  modifyRole: '/privilegeRole/modifyRole', // 编辑角色
  privilegeNode: '/privilegeNode/list', // 权限列表
  roleDetail: '/privilegeRole/detail', // 角色详情
  changeCompany: '/login/changeCompany', // 改变公司

  // 系统配置
  systemList: '/systemConfigGroup/list', // 获取系统配置列表
  sysEdit: '/systemConfigDetail/update', // 系统配置编辑

  // 通用配置
  configList: '/configOptionGroup/list',
  addOneOptionDetail: 'configOptionDetail/addOneOptionDetail',
  updateOneOptionDetail: '/configOptionDetail/updateOneOptionDetail',
  deleteOneOptionDetail: '/configOptionDetail/deleteOneOptionDetail',

  // 模板管理
  messageTemplateList: '/messageTemplate/list',
  modifyTemplate: '/messageTemplate/modifyTemplate', // 编辑模板
  detailTemplate: '/messageTemplate/detail',

  // 账本统计
  accountList: '/account/statistic/external',
  userDeptList: '/userDept/list',
  accountExport: '/account/statistic/internalListExport',
  accountInExport: '/account/statistic/externalListExport',
  internal: 'account/statistic/internal',
  // 自筹资金统计
  self_fund: '/account/statistic/self_fund',
  selfFundListExport: 'account/statistic/selfFundListExport',

  // 综合统计
  synthesizeList: '/account/report/department',
  synthesizeExport: '/account/report/departmentExport',

  // 经费管理
  // 项目账本
  fundsList: '/fundManagement/list', // 获取经费管理总列表
  czhxxmDetail: '/fundManagement/queryHorizontal', // 财政横向详情
  zyxmDetail: '/fundManagement/querySelf', // 创新发展项目详情
  czhxAndczzxEdit: '/fundManagement/editHorizontalAndSpecial', // 财政横向以及财政专项编辑
  editSpecialEstimate: '/fundManagement/editSpecialEstimate', // 项目账本编辑

  // 报销登记
  // spendRegisterList: '/fundsReimbursement/selectListPage', // 获取列表
  spendRegisterList: '/fundsReimbursement/khbselectListPage',
  getType: '/fundsReimbursement/khbgetFundsType', // 查询科研编号、支出类型、科目等信息
  addRegister: '/fundsReimbursement/addReimbursement', // 添加报销
  exportRegisterList: '/fundsReimbursement/khbexportList', // 导出报销列表
  downLoadCopyRegister: '/fundsReimbursement/khbimportTemplate', // 下载导入模板
  importRegister: '/fundsReimbursement/khbimportData', // 导入报销登记
  exportRegisterZip: '/fundsReimbursement/downloadVoucherZip', // 导出报销凭证
  editAllTable: '/fundsReimbursement/updateList', // 批量修改表格
  // 删除报销
  deleteRegister: '/fundsReimbursement/delReimbursement',
  // 报销登记 end

  // 项目财政收支明细
  projectFundsDetailList: '/fundsGovInout/khbselectListPage',
  // 查询部门信息
  getDepList: '/fundsReimbursement/getDeptList',
  // 新增
  addProjectFunds: '/fundsGovInout/addGovInout',
  // 查找财务编号
  FindCwNm: '/fundsReimbursement/khbgetFundsType',
  // 编辑
  editProjectFunds: '/fundsGovInout/updateGovInout',
  // 删除
  deleteProjectFunds: '/fundsGovInout/delGovInout',
  // 下载导入模板
  downLoadCopy: '/fundsGovInout/importTemplate',
  // 导入收支明细
  importDetail: '/fundsGovInout/khbimportData',
  // 导出收支明细列表
  exportDetail: '/fundsGovInout/khbexportList',
  // 导出详情凭证
  downLoadDetaikZip: '/fundsGovInout/downloadVoucherZip',
  // end 项目财政收支明细

  // 重用统计
  reuseList: '/fundsReimbursement/khbselectReusingListPage', // 列表
  exportReuse: '/fundsReimbursement/khbexportReusingList', // 导出
  // end 重用统计

  // 经费预警
  fundsPreWarningList: '/fundsWarning/selectListPage', // 列表
  addPreWarning: '/fundsWarning/addFundsWarning', // 新增
  deletePreWarning: '/fundsWarning/delFundsWarning', // 删除
  editPreWaring: '/fundsWarning/updateFundsWarning', // 编辑
  // end 经费预警

  // 项目人员管理
  projectPresonlist: '/userInfo/selectUserListPage', // 列表
  projectDetailList: '/userInfo/selectUserDetailListPage', // 详情
  // exportProjectPerson: '/userInfo/exportUserProjectList', // 导出 /userInfo/exportUserProjectList
  exportProjectPerson: '/userInfo/khbexportUserProjectList',

  // 档案
  archivesList: '/fileManagement/selectFilePage',
  archivesExport: '/fileManagement/khbexportFileList',
  archivesListPro: '/fileManagement/proList',
  archivesDownloadAll: '/fileManagement/downloadZip/',
  archivesDownloadChoice: '/fileManagement/downloadFileZip',
  archivesDownloadType: '/fileManagement/downloadFileByType',
  archivesDownloadFile: '/fileManagement/downloadFile/',
  archivesDetail: '/fileManagement/selectProjectFile',
  // end 档案

  fundList: '/fundManagement/list',
  fundBaseExport: '/fundManagement/exportList',
  fundExport: '/fundManagement/exportFundList',
  // end 经费

  // 综合统计相关接口
  compreHxxmList: '/projectTransverseInfo/selectComprehensiveHxxmListPage',
  compreCzhxxmList: '/projectTransverseInfo/selectComprehensiveCzhxxmListPage',
  compreCzzxxmList: '/projectTransverseInfo/selectComprehensiveCzzxxmListPage',
  compreZyxmList: '/projectTransverseInfo/selectComprehensiveZyxmListPage',
  findComprehensive: '/projectTransverseInfo/khbfindComprehensive',
  compreQtxmList: '/projectTransverseInfo/selectComprehensiveQtxmListPage',
  // findComprehensive: '/projectTransverseInfo/findComprehensive',

  // 导出
  compreHxxmExport: '/projectTransverseInfo/exportComprehensiveHxxmList',
  compreCzhxxmExport: '/projectTransverseInfo/exportComprehensiveCzhxxmList',
  compreCzzxxmExport: '/projectTransverseInfo/exportComprehensiveCzzxxmList',
  compreZyxmLExport: '/projectTransverseInfo/exportComprehensiveZyxmList',
  exportComprehensive: '/projectTransverseInfo/khbexportComprehensive',
  compreQtxmExport: '/projectTransverseInfo/exportComprehensiveQtxmList',
  // exportComprehensive: '/projectTransverseInfo/exportComprehensive',
  // 生成申报材料
  getProjectApplication: '/projectApplication/getProjectApplication/',

  // 新增项目
  businessUserList: '/projectTransverseInfo/findProBusinessTypeUser',
  subTypeList: '/projectTransverseInfo/findProBusinessSubType',
  projectAdd: '/projectTransverseInfo/addProjectDraft',
} as const

export default URLLIST
